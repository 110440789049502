'use client';

import LoginByEmail from './components/LoginByEmail/LoginByEmail';

export type LoginFormInput = {
  email: string;
  password: string;
  cpf: string;
};

export type LoginMethod = 'email' | 'cpf' | 'phone';
export type SecondaryMethod = 'first-access' | 'forgot-pwd' | 'signup';

export default function Login() {
  return <LoginByEmail />;
}
