export const customInputProps = {
  styles: {
    input: {
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderRadius: 0,
    },
  },
  classNames: {
    error: 'text-red-100 mt-1',
    input: '!text-light-600 bg-dark-900 bg-opacity-20',
    label: '!text-light-600',
  },
};
