import { useState, forwardRef } from 'react';
import {
  NumberInput,
  NumberInputProps,
  PasswordInput,
  PasswordInputProps,
  TextInput,
  TextInputProps,
} from '@mantine/core';
import classes from './FloatingLabel.module.css';
import { mergeClassNamesObj } from '@/utils/merge';

export const FloatingLabelTextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ classNames, labelProps, ...props }, ref) => {
    const [focused, setFocused] = useState(false);
    const value = props.value as string;
    const floating = value?.trim().length !== 0 || focused || undefined;

    return (
      <TextInput
        ref={ref}
        mt="lg"
        data-floating={floating}
        classNames={mergeClassNamesObj(classes, (classNames || {}) as Record<string, string>)}
        labelProps={{ 'data-floating': floating, ...labelProps }}
        {...props}
        onFocus={(e) => {
          setFocused(true);
          props.onFocus?.(e);
        }}
        onBlur={(e) => {
          setFocused(false);
          props.onBlur?.(e);
        }}
      />
    );
  },
);

export const FloatingLabelNumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  ({ classNames, labelProps, ...props }, ref) => {
    const [focused, setFocused] = useState(false);
    const value = props.value;
    const floating = value?.toString().trim().length !== 0 || focused || undefined;

    return (
      <NumberInput
        ref={ref}
        mt="lg"
        data-floating={floating}
        classNames={mergeClassNamesObj(classes, (classNames || {}) as Record<string, string>)}
        labelProps={{ 'data-floating': floating, ...labelProps }}
        {...props}
        onFocus={(e) => {
          setFocused(true);
          props.onFocus?.(e);
        }}
        onBlur={(e) => {
          setFocused(false);
          props.onBlur?.(e);
        }}
      />
    );
  },
);

export const FloatingLabelPasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ classNames, labelProps, ...props }, ref) => {
    const [focused, setFocused] = useState(false);
    const value = props.value as string;
    const floating = value?.trim().length !== 0 || focused || undefined;

    return (
      <PasswordInput
        ref={ref}
        mt="lg"
        data-floating={floating}
        classNames={mergeClassNamesObj(classes, (classNames || {}) as Record<string, string>)}
        labelProps={{ 'data-floating': floating, ...labelProps }}
        {...props}
        onFocus={(e) => {
          setFocused(true);
          props.onFocus?.(e);
        }}
        onBlur={(e) => {
          setFocused(false);
          props.onBlur?.(e);
        }}
      />
    );
  },
);
