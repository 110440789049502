'use client';

import { User } from '@/typings/user';
import { Alert, Anchor, Box, Button, Center, Group, Text, Title } from '@mantine/core';
import { isEmail, isNotEmpty, useForm } from '@mantine/form';
import { ReactNode, useEffect, useState } from 'react';
import { LoginFormInput } from '../LoginByEmail/LoginByEmail';
import useApi from '@/hooks/useApi/useApi';
import { USER_TOKEN } from '@/utils/storageKeys';
import { deleteCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/navigation';
import { useStore } from '@/stores/store';
import {
  FloatingLabelPasswordInput,
  FloatingLabelTextInput,
} from '@/components/FloatingLabelComponents/FloatingLabelInput';
import { customInputProps } from '@/app/entrar/customInputProps';
import { IconArrowLeft } from '@tabler/icons-react';
import { setAxiosAuthentication } from '@/utils/axios';
import { shouldSkipLoggingForAuthError } from '@/utils/error';

type Props = {
  initialValues?: Partial<LoginFormInput>;
  defaultFocus?: keyof LoginFormInput;
  onGoBack: (value?: string) => void;
};

export default function FirstAccess({ onGoBack, initialValues, defaultFocus }: Props) {
  const { put, loading } = useApi<{ email: string; password: string }>('createPassword');
  const { get: getAuthUser } = useApi('me');
  const router = useRouter();
  const updateData = useStore((store) => store.updateData);
  const setOrganization = useStore((store) => store.setOrganization);

  const [errorNode, setErrorNode] = useState<ReactNode>();

  const form = useForm<LoginFormInput>({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      ...initialValues,
    },
    validate: {
      password: isNotEmpty('Insira uma senha'),
      confirmPassword: (value, values) =>
        value === values.password ? undefined : 'As senhas precisam ser iguais',
      email: isEmail('Você precisa digitar um e-mail válido'),
    },
    validateInputOnBlur: true,
  });

  const handleSubmit = async ({ password, ...values }: LoginFormInput) => {
    setErrorNode(undefined);
    put<{ token: string }>(
      { password, email: values.email },
      {
        error: {
          message: 'Ocorreu um erro ao criar a senha. Tente novamente',
          showNotification: true,
          shouldSkipLogging: shouldSkipLoggingForAuthError,
        },
        onSuccess: ({ token }) => {
          localStorage.setItem(USER_TOKEN, token);
          const maxAge = 1000 * 60 * 60 * 24 * 365; // 1 year for now
          setCookie(USER_TOKEN, token, { maxAge });
          setAxiosAuthentication(token);
          getAuthUser<User>({
            error: {
              message: 'Error on logging user after creating password',
              showNotification: false,
            },
            onSuccess: (user) => {
              updateData('user', () => user);
              const org = user.organizations?.[0]?.organization;
              if (org) {
                setOrganization(org);
              }
              router.push('/');
            },
            onError: () => {
              deleteCookie(USER_TOKEN);
              localStorage.removeItem(USER_TOKEN);
              setErrorNode(
                <Alert color="primary" radius="md" className="info text-center">
                  Sua senha foi registrada com sucesso! Porém, não conseguimos realizar sua
                  autenticação ainda. Clique{' '}
                  <Anchor
                    component="button"
                    c="primary"
                    fw="bold"
                    onClick={() => onGoBack(values['email'])}
                  >
                    aqui
                  </Anchor>{' '}
                  para entrar
                </Alert>,
              );
            },
          });
        },
        onError: (error) => {
          if (error?.message.includes('User not found')) {
            setErrorNode(
              <Alert color="yellow" radius="md" className="warn">
                Usuário não encontrado. Entre em contato com o administrador do sistema
              </Alert>,
            );
          }

          if (error?.message.includes('User already has a password')) {
            setErrorNode(
              <Alert color="primary" radius="md" className="info">
                <Text c="light">
                  Você já se registrou anteriormente. Clique{' '}
                  <Anchor
                    component="button"
                    c="primary"
                    fw="bold"
                    onClick={() => onGoBack(form.values.email)}
                  >
                    aqui
                  </Anchor>{' '}
                  para entrar
                </Text>
              </Alert>,
            );
          }
        },
      },
    );

    // get<User[]>({
    //   error: { message: 'Error on getting users on first access', showNotification: false },
    //   onSuccess: (users) => {
    //     const user = users.find((u) => u.email === values.email);

    //     if (user) {
    //       if (user?.password) {
    //         setErrorNode(
    //           <Alert color="blue" radius="md" className="info">
    //             Você já se registrou anteriormente. Clique{' '}
    //             <Anchor
    //               component="button"
    //               c="blue"
    //               fw="bold"
    //               onClick={() => onGoBack(values['email'])}
    //             >
    //               aqui
    //             </Anchor>{' '}
    //             para entrar
    //           </Alert>,
    //         );
    //       } else {
    //         put<{ email: string; password: string }>({ password, email: values.email }, user.id, {
    //           error: {
    //             message: 'Ocorreu um erro ao criar a senha. Tente novamente',
    //             showNotification: true,
    //           },
    //         });
    //       }
    //     } else {
    //       setErrorNode(
    //         <Alert color="yellow" radius="md" className="warn">
    //           Usuário não encontrado. Entre em contato com o administrador do sistema
    //         </Alert>,
    //       );
    //     }
    //   },
    // });
  };

  useEffect(() => {
    if (defaultFocus) {
      const input = form.getInputNode(defaultFocus);
      if (input) {
        input.focus();
      }
    }
  }, [defaultFocus]);

  return (
    <form className="flex h-full flex-col text-white" onSubmit={form.onSubmit(handleSubmit)}>
      <div>
        <Title order={1} ta="center" mb="sm">
          Primeiro acesso
        </Title>
        <Text ta="center" mb="lg">
          Como este é seu primeiro acesso, informe seu email (por onde foi convidado) e crie uma
          senha de acesso.
        </Text>
      </div>
      <div className="flex flex-col gap-4 animate-[fade-in_0.5s_ease-in]">
        <FloatingLabelTextInput
          {...customInputProps}
          {...form.getInputProps('email')}
          type="email"
          label="E-mail"
          autoComplete="on"
        />

        <FloatingLabelPasswordInput
          {...customInputProps}
          {...form.getInputProps('password')}
          label="Crie uma senha"
        />
        <FloatingLabelPasswordInput
          {...customInputProps}
          {...form.getInputProps('confirmPassword')}
          label="Confirmar senha"
        />

        {errorNode}

        <Group justify="space-between" align="center" mt="md">
          <Anchor
            c="accent"
            type="button"
            component="button"
            onClick={() => onGoBack(form.values.email)}
          >
            <Center inline>
              <IconArrowLeft size={18} stroke={1.5} style={{ marginBottom: 4 }} />
              <Box ml={5}>Voltar</Box>
            </Center>
          </Anchor>

          <Button type="submit" variant="gradient" loading={loading}>
            Criar conta
          </Button>
        </Group>
      </div>
    </form>
  );
}
