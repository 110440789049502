'use client';

import { Alert, Anchor, Button, Text, Title } from '@mantine/core';
import { isEmail, useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import FirstAccess from '../FirstAccess/FirstAccess';
import { SecondaryMethod } from '../../FirebaseLogin';
import useApi, { ErrorType } from '@/hooks/useApi/useApi';
import { useRouter } from 'next/navigation';
import { setCookie } from 'cookies-next';
import { User } from '@/typings/user';
import { useStore } from '@/stores/store';
import { USER_TOKEN } from '@/utils/storageKeys';
import {
  FloatingLabelPasswordInput,
  FloatingLabelTextInput,
} from '@/components/FloatingLabelComponents/FloatingLabelInput';
import { customInputProps } from '@/app/entrar/customInputProps';
import { setAxiosAuthentication } from '@/utils/axios';
import { shouldSkipLoggingForAuthError } from '@/utils/error';

export type LoginFormInput = {
  email: string;
  password: string;
  confirmPassword?: string;
};

enum LoginErrorMessage {
  PendingSubscription = 'Waiting for payment confirmation',
  Unfinished = 'Waiting to complete the purchase',
  NoPassword = 'User does not have password',
  WrongPassword = 'Wrong password',
  UserNotFound = 'User not found',
  InactiveOrganization = 'User has inactive organizations',
}

function AuthErrorMessage({
  error,
  onCreatePassword,
}: {
  error: ErrorType;
  onCreatePassword: () => void;
}) {
  if (error.status !== 401 || !error.message) return 'Ocorreu um erro. Tente novamente';

  if (error.message.includes(LoginErrorMessage.NoPassword)) {
    return (
      <div className="flex flex-col">
        <p>Essa conta ainda não possui uma senha </p>
        <Anchor
          c="red"
          ml="auto"
          type="button"
          component="button"
          onClick={onCreatePassword}
          className="text-inherit w-max mx-auto"
        >
          Criar senha
        </Anchor>
      </div>
    );
  }

  if (error.message.includes(LoginErrorMessage.PendingSubscription)) {
    return 'Aguardado confirmação de pagamento. Tente novamente mais tarde.';
  }

  return 'E-mail ou senha inválida';
}

export default function LoginByEmail() {
  const [secondaryMethod, setSecondaryMethod] = useState<SecondaryMethod>();

  const { loading, error, post, setLoading } = useApi('auth');
  const updateData = useStore((store) => store.updateData);
  const setOrganization = useStore((store) => store.setOrganization);

  const [defaultFocus, setDefaultFocus] = useState<keyof LoginFormInput>();

  const router = useRouter();

  const form = useForm<Omit<LoginFormInput, 'cpf'>>({
    validateInputOnBlur: true,
    initialValues: { email: '', password: '' },
    validate: {
      email: isEmail('Você precisa digitar um e-mail válido'),
    },
  });

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  async function handleSubmit({ email, password }: { email: string; password: string }) {
    post<{ token: string; user: User }>(
      { email, password },
      {
        error: {
          message: 'Error on login',
          showNotification: false,
          shouldSkipLogging: shouldSkipLoggingForAuthError,
        },
        onSuccess: async (res) => {
          const { token, user } = res;

          // Keeps loading TRUE because we were having no navigation feedback after a successful login
          setLoading(true);

          localStorage.setItem(USER_TOKEN, token);
          const maxAge = 1000 * 60 * 60 * 24 * 365; // 1 year for now
          setCookie(USER_TOKEN, token, { maxAge });
          setAxiosAuthentication(token);
          updateData('user', () => user);
          const org = user.organizations?.[0]?.organization;
          if (org) {
            setOrganization(org);
          }
          router.push('/');
        },
      },
    );
  }

  function handleGoBack(email?: string) {
    if (email) form.setFieldValue('email', email);
    setSecondaryMethod(undefined);
  }

  return (
    <>
      {!secondaryMethod && (
        <form className="flex h-full flex-col text-white" onSubmit={form.onSubmit(handleSubmit)}>
          <div>
            <Title order={1} ta="center">
              Bem vindo
            </Title>
            <Text ta="center" mb="lg">
              Entre com seu e-mail e senha
            </Text>
          </div>
          <div className="flex flex-col gap-4 animate-[fade-in_0.5s_ease-in]">
            <FloatingLabelTextInput
              {...customInputProps}
              {...form.getInputProps('email')}
              type="email"
              label="E-mail"
              autoComplete="email"
            />

            <FloatingLabelPasswordInput
              {...customInputProps}
              {...form.getInputProps('password')}
              label="Senha"
            />

            {error && (
              <Alert color="red" radius="md" classNames={{ message: 'text-center text-red-600' }}>
                <AuthErrorMessage
                  error={error}
                  onCreatePassword={() => {
                    setSecondaryMethod('first-access');
                    setDefaultFocus('password');
                  }}
                />
              </Alert>
            )}

            <Button type="submit" variant="gradient" fullWidth loading={loading}>
              {loading ? 'Autenticando' : 'Entrar'}
            </Button>
            <Text ta="center" mt="md" className="mt-auto" w="100%">
              <Anchor
                c="accent"
                component="button"
                type="button"
                onClick={() => setSecondaryMethod('first-access')}
              >
                Meu primeiro acesso
              </Anchor>
            </Text>
          </div>
        </form>
      )}
      {secondaryMethod === 'first-access' && (
        <FirstAccess
          defaultFocus={defaultFocus}
          initialValues={{ email: form.values.email }}
          onGoBack={handleGoBack}
        />
      )}
    </>
  );
}
